import React, { useState, useEffect } from "react";
import Project1 from "../utils/images/project1.png";
import Project2 from "../utils/images/project2.png";
import Project3 from "../utils/images/project3.png";
import { Projects } from "../utils/Data";
import { Link } from "react-router-dom";

type GalleryProps = {};

function Gallerys(props: GalleryProps) {
  const [activeLink, setActiveLink] = useState<number>(0);
  const [images, setImages] = useState<string[]>([]);
  const [videos, setVideos] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const choose: string[] = [
    "Civil Engineering",
    "Oil & Gas",
    "Project Management",
    "Training Services",
    "General Contracts",
    "Supplies",
  ];

  useEffect(() => {
    const loadContent = async () => {
      setIsLoading(true);

      if (activeLink === 0) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      } else {
        // Simulating an async loading of videos
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      setIsLoading(false);
    };

    loadContent();
  }, [activeLink]);

  return (
    <div className="lg:px-14 xl:px-28 mt-24">
      <section>
        {/* First Section */}
        <section className="lg:px-10   text-base z-50 relative">
          <div className="text-center flex flex-wrap lg:flex-nowrap justify-center gap-2 bg-black rounded-full py-3 text-white mt-10">
            {choose.map((ch, index) => (
              <p key={index} className="self-start">
                <button
                  onClick={() => setActiveLink(index)}
                  className={
                    activeLink === index
                      ? "border px-5 py-2 rounded-full text-black bg-white"
                      : "text-white px-5 py-2"
                  }
                >
                  {ch}
                </button>
              </p>
            ))}
          </div>
        </section>

        {/* Second Section */}
        <section className="flex  flex-wrap  justify-between md:grid gap-5 grid-cols-2 px-3 lg:px-10 border py-20 -mt-5 bg-[#FFE1E2] rounded-3xl relative z-10">
          {Projects.map((pro, index) => (
            <div className="w-full">
              <Link to="done">
                {" "}
                <div key={pro.id} className="space-y-4 w-full ">
                  <p>
                    <img
                      src={pro.img}
                      alt=""
                      className="w-full lg:w-[740px] lg:h-[450px]"
                    />
                  </p>
                  <p className="font-epilogue text-2xl tlg:ext-3xl font-bold">
                    {pro.title}
                  </p>
                  <p>{pro.summ}</p>

                  <p className="underline text-[#A02B2D] text-lg">
                    {" "}
                    <a href="/home">View project </a>{" "}
                  </p>
                </div>{" "}
              </Link>
            </div>
          ))}
        </section>
      </section>
    </div>
  );
}

export default Gallerys;
