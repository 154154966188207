import React from "react";
import Hero from "../utils/images/aboutus.png";
import Gallery from "./Gallery";
import { Others } from "../utils/Data";
import Stars from "../utils/images/star.png";

function services() {
  return (
    <div>
      <section className="relative">
        <div>
          <img
            src={Hero}
            alt="hero"
            className="w-full object-cover h-[50vh] lg:h-full"
          />
        </div>

        <div className="space-y-5 lg:space-y-10 absolute  top-32 lg:top-14 xl:top-32 px-2 lg:px-28 text-center text-white">
          <h1 className="text-3xl lg:text-5xl xl:text-7xl font-bold">
            Showcasing Excellence in Every Project
          </h1>
          <p className="lg:text-2xl">
            Delivering excellence across civil engineering, oil and gas, and
            global project management. Our expertise drives innovation, fuels
            progress, and builds a sustainable future.
          </p>
        </div>
      </section>

      <section className="lg:px-14 xl:px-28 px-3 mt-14 lg:mt-24 flex justify-between gap-10 lg:gap-32 flex-wrap lg:flex-nowrap">
        <div className="space-y-5  mt-5">
          <h1 className="text-[27px] lg:text-[40px] xl:text-[55px] text-[#A02B2D] font-bold">
            What We Offer
          </h1>
          <p className=" text-lg leading-9 xl:text-2xl lg:leading-[55px] font-medium">
            Building Excellence Construction specializes in a wide range of
            construction services, including:
          </p>
        </div>
      </section>

      <section>
        <Gallery />
      </section>

      <section className="hidden lg:px-14 xl:px-28 mt-24 lg:block">
        <div className="text-center">
          <h1 className="text-[32px] lg:text-[55px] font-semibold text-[#A02B2D]">
            What others are saying about Us
          </h1>
        </div>

        <section className="flex lg:justify-between flex-wrap lg:flex-nowrap mt-14 gap-5">
          {Others.map((say) => (
            <div className=" space-y-5 w-[500px] mt-10 lg:mt-0">
              <p>
                <img src={say.img} alt="" />
              </p>
              <h1 className="text-3xl font-medium">{say.title}</h1>
              <p className="leading-10 text-xl"> {say.summ}</p>
              <p>
                <img src={Stars} alt="" />
              </p>
              <p className="text-lg">{say.pro}</p>
            </div>
          ))}
        </section>
      </section>

      {/* For mobile */}
      <section className="lg:hidden mt-24 px-5 flex-grow">
        <div className="text-center">
          <h1 className="text-[32px] lg:text-[55px] font-semibold text-[#A02B2D]">
            What others are saying about Us
          </h1>
        </div>

        <section className="flex lg:justify-between flex-wrap lg:flex-nowrap mt-5 flex-grow">
          {Others.slice(0, 1).map((say) => (
            <div className="flex flex-col justify-center items-center space-y-5 w-[500px] mt-10 lg:mt-0 flex-grow">
              <p>
                <img src={say.img} alt="" />
              </p>
              <h1 className="text-3xl font-medium">{say.title}</h1>
              <p className="leading-10 text-xl"> {say.summ}</p>
              <p>
                <img src={Stars} alt="" />
              </p>
              <p className="text-lg">{say.pro}</p>
            </div>
          ))}
        </section>
      </section>
      {/* End  */}
    </div>
  );
}

export default services;
