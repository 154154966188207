import React, { useEffect } from 'react'
import { News} from "../utils/Data";
import Mobile from '../utils/images/mobile.png'
import FMobile from '../utils/images/wob.png'

import TechnologyImg from '../utils/images/technology.png'
import Person from '../utils/images/person.png'
import Tech2 from '../utils/images/tech2.png'

function Technology() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>

        <section className='mt-24 px-5 lg:px-14 xl:px-28'>
            <div className='flex gap-8 flex-col items-start'>
              <p className='py-2 px-3 rounded-xl bg-[#A02B2D] text-white font-medium'>Technology</p>
                <h1 className='lg:w-[800px] text-[#181A2A] font-semibold text-3xl tlg:ext-4xl leading-[50px]'>The Impact of Technology on the Workplace: How Technology is Changing</h1>

                <div className='flex space-x-4'>
                  <p> <img src={Person} alt="" /> </p>
                  <p className='text-[#97989F]'>Tracey Wilson</p>
                  <p className='text-[#696A75]'>August 20, 2022</p>
                </div>
            </div>

            <div className='mt-12'>
                <img src={TechnologyImg} alt="" className='w-full hidden lg:block  lg:h-full object-cover' />
                <img src={Mobile} alt="" className='lg:hidden' />
           </div>
           <div className='text-xl text-[#3B3C4A] mt-10'>
            <div className='space-y-8'>
            <p className='leading-[35px]'>Traveling is an enriching experience that opens up new horizons, exposes us to different cultures, and creates memories that last a lifetime. However, traveling can also be stressful and overwhelming, especially if you don't plan and prepare adequately. In this blog article, we'll explore tips and tricks for a memorable journey and how to make the most of your travels.</p>

<p className='leading-[35px]'>One of the most rewarding aspects of traveling is immersing yourself in the local culture and customs. This includes trying local cuisine, attending cultural events and festivals, and interacting with locals. Learning a few phrases in the local language can also go a long way in making connections and showing respect.</p>
            </div>
          

            <h1 className='text-[#181A2A] text-2xl font-semibold mt-10 mb-4'>Research Your Destination</h1>
            <div className='space-y-8'>
           <p className='leading-[35px]'>Before embarking on your journey, take the time to research your destination. This includes understanding the local culture, customs, and laws, as well as identifying top attractions, restaurants, and accommodations. Doing so will help you navigate your destination with confidence and avoid any cultural faux pas.</p>

            <p className='leading-[35px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. In hendrerit gravida rutrum quisque non tellus orci ac auctor. Mi ipsum faucibus vitae aliquet nec ullamcorper sit amet. Aenean euismod elementum nisi quis eleifend quam adipiscing vitae. Viverra adipiscing at in tellus.</p>
            </div>
            <h1 className='text-[#181A2A] text-2xl font-semibold mt-10 mb-4'>Plan Your Itinerary</h1>
            <div className='space-y-8'> 
              <p className='leading-[35px]'>While it's essential to leave room for spontaneity and unexpected adventures, having a rough itinerary can help you make the most of your time and budget. Identify the must-see sights and experiences and prioritize them according to your interests and preferences. This will help you avoid overscheduling and ensure that you have time to relax and enjoy your journey.</p>

              <p className='leading-[35px]' >Vitae sapien pellentesque habitant morbi tristique. Luctus venenatis lectus magna fringilla. Nec ullamcorper sit amet risus nullam eget felis. Tincidunt arcu non sodales neque sodales ut etiam sit amet.</p>


            </div>


            <div className='px-10 py-5 text-2xl bg-[#F6F6F7] mt-10 rounded-xl'>
           “ Traveling can expose you to new environments and potential health risks, so it's crucial to take precautions to stay safe and healthy. ”
           </div>

           </div>


          


           <div className='mt-12'>
                <img src={Tech2} alt="" className='w-full h-full object-cover hidden lg:block' />
                <img src={FMobile} alt="" className='lg:hidden' />
           </div>

               <h1 className='text-[#181A2A] text-2xl font-semibold mt-10 mb-4'>Conclusion:</h1>
            <div className='space-y-8 text-xl'>
           <p className='leading-[35px]'>Traveling is an art form that requires a blend of planning, preparation, and spontaneity. By following these tips and tricks, you can make the most of your journey and create memories that last a lifetime. So pack your bags, embrace the adventure, and enjoy the ride..</p>

            </div>


            <section className=" mt-14">
            <section className="flex justify-between"> 
            <div className="w-[732px]">
                <h1 className="text-3xl font-bold">Related  News </h1>
            </div>
           </section>


           <section className="flex justify-between flex-wrap lg:flex-nowrap lg:mt-14 gap-5">
            {News.map((news) => (
                <div className="border rounded-lg space-y-5 mt-10 lg:mt-0  xl:w-[500px] py-5 px-5">
                    <p className="flex justify-center">
                        <img src={news.img} alt="" />
                    </p>
                    <div className="flex justify-start">
                    <p className="border text-start  px-2 text-[#4B6BFB] py-1 text-sm">{news.category}</p>
                    </div>
                    <h1 className="font-semibold text-2xl">{news.title}</h1>
                    <div className="flex space-x-4">
                        <p><img src={news.proImg} alt="" /></p>
                        <p className="mt-1 text-[#97989F]">{news.name}</p>
                        <p className="mt-1 text-[#97989F]">{news.detail}</p>
                    </div>
                </div>
            ))}

           </section>
           </section>
        </section>
      
    </div>
  )
}

export default Technology
