import React from "react";
import Hero from "../utils/images/aboutus.png";

function Offer() {
  return (
    <div>
      <section className="relative">
        <div>
          <img
            src={Hero}
            alt="hero"
            className="w-full object-cover h-[50vh] lg:h-full"
          />
        </div>

        <div className="space-y-5 lg:space-y-10 absolute top-32 px-2 lg:px-28 text-center text-white">
          <h1 className="text-3xl lg:text-7xl font-bold">
            Showcasing Excellence in Every Project
          </h1>
          <p className="lg:text-2xl">
            Delivering excellence across civil engineering, oil and gas, and
            global project management. Our expertise drives innovation, fuels
            progress, and builds a sustainable future.
          </p>
        </div>
      </section>
    </div>
  );
}

export default Offer;
