import React, { useState } from "react";
import Logo from "../utils/images/3.png";
import { MainNavbarItems } from "../utils/Data";
import { NavLink } from "react-router-dom";
import {
  FaBars,
  FaTimes,
  FaLocationArrow,
  FaPhone,
  FaMailBulk,
} from "react-icons/fa";
import { motion } from "framer-motion";

const MobileNav: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="lg:hidden">
      {/* Top Navbar with Contact Info */}
      <nav className="bg-black flex text-[10px] justify-end items-center space-x-2 text-white py-3 px-2">
        <div className="flex space-x-2">
          <FaPhone className="mt-1" />
          <p>Tel: +234 (0) 8059438246</p>
        </div>
        <div className="flex space-x-2">
          <FaMailBulk className="mt-1" />
          <p>info@veekites.com</p>
        </div>
        <div className="flex space-x-2 lowercase">
          <FaLocationArrow className="mt-1" />
          <p>
            PLOT 141 MEIMOGHA LAYOUT, OKWISOKO, OFF JAKPA ROAD EFFURUN, DELTA
            STATE
          </p>
        </div>
      </nav>

      {/* Mobile Navbar Toggle and Logo */}
      <section className="px-5 py-3">
        <nav className="flex justify-between">
          <div>
            <img src={Logo} alt="logo" className="w-[60%]" />
          </div>

          <div>
            <motion.div
              initial={{ rotate: 0 }}
              animate={{ rotate: menuOpen ? 180 : 0 }}
              transition={{ duration: 0.3 }}
              onClick={toggleMenu}
              className="cursor-pointer"
            >
              {menuOpen ? (
                <FaTimes className="text-2xl mt-2" />
              ) : (
                <FaBars className="text-2xl mt-2" />
              )}
            </motion.div>
          </div>
        </nav>

        {/* Mobile Navbar Items */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: menuOpen ? 1 : 0, y: menuOpen ? 0 : 20 }}
          transition={{ duration: 0.3 }}
          className={`fixed  pb-5 z-50 left-0 w-full bg-white shadow-lg ${
            menuOpen ? "block" : "hidden"
          } flex flex-col items-center`}
        >
          <ul className="w-full text-xl space-y-5 text-[#222222] px-5 py-5">
            {MainNavbarItems.map((item) => (
              <li
                key={item.id}
                className="w-full text-center"
                onClick={toggleMenu}
              >
                <NavLink
                  to={item.link}
                  className={({ isActive }) =>
                    isActive
                      ? "uppercase font-bold border-b-4 border-[#A02B2D] pb-2"
                      : "uppercase font-normal"
                  }
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </motion.div>
      </section>
    </div>
  );
};

export default MobileNav;
