import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar.tsx'
import Home from './components/Home/Home.tsx'
import Fotter from './components/Fotter/Fotter.tsx'
import About from './components/About/About.tsx' 
import News from './components/News/News.tsx'
import Technology from './components/News/Technology.tsx'
import Projects from './components/Project/Projects.tsx'
import Projectes from './components/Project/Projectes.tsx'
import Contact from './components/Contact/Contact.tsx'
import Career from './components/Career/Career.tsx'
import Job from './components/Career/Job.tsx'
import { Routes, Route } from 'react-router-dom';
import MobileNav from './components/Navbar/MobileNav.tsx'
import Offer from './components/Project/Offer.tsx'
import Services from './components/Service/Services.tsx'

function App() {
  return (
    <div>
      <Navbar />
      <MobileNav />
      <Routes>
        <Route path='/' element={   <Home /> } />
        <Route path='/about' element={ <About /> } />
        <Route path='/news' element={ <News /> }>
        <Route path='tech' element={ <Technology /> } />
        </Route>
        <Route path='/project' element={ <Projects /> }>
        <Route path='done' element={ <Projectes /> } />
        <Route path='offer' element={ <Offer /> } />
        </Route>
        <Route path='/contact' element={ <Contact /> } />
        <Route path='/career' element={ <Career /> }>
        <Route path='job' element={ <Job /> } />
        </Route>
        <Route path='service' element={ <Services /> } />
      </Routes>
      <Fotter />
    </div>
  );
}

export default App;
