import React, { useEffect } from 'react'
import JobImag from '../utils/images/car.png'
import Mobile from '../utils/images/mobileJob.png'
import { FaArrowRight, FaLocationArrow } from 'react-icons/fa'
import Location from '../utils/images/location.png'
import {  Positions } from '../utils/Data'


function Job() {
  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const responsible = [     
    "Plan, implement and monitor all digital marketing campaigns, including SEO/SEM, and display advertising across all digital networks.",
    "Develop and monitor campaign budgets",
    "Identify trends and insights, and optimise spending and performance based on insights.",
    "Develop strategies that drive customers to the app store for acquisitions",
    "Collaborate with internal teams to create landing pages that would optimise mobile downloads and acquisition",
    "Suggest activities for improving the quality of content online",
    "Oversee and manage all contests, giveaways, and other digital projects",
    "Work with your team to brainstorm new and innovative growth strategies",
    "Prepare accurate reports on our marketing campaign’s overall performance",
    "Stay up to date with the latest technology, industry and market trends, and best practices",
  ]

  const mustHave = [
"A minimum of 2 years experience as a Digital Marketing Manager with experience in mobile acquisition",
"Highly creative with experience in identifying target audiences and devising digital campaigns that engage, inform, and motivate",
"Experience in optimising landing pages and user funnels",
"Solid knowledge of analytics tools and MMPs (E.g., Google analytics, AppsFlyer, Firebase, etc.)",
"     Working knowledge of Ad serving tools" ,
"Outstanding communication and interpersonal skills",
"Up to date on the latest trends and technologies in digital marketing"
  ]

  const Nice = [
    "Copywriting abilities",
"Have an idea of what good design is",
"Self-starter with the ability to work independently and collaborate with a team"
   ]

  return (
    <div>
    <section>
      <img src={JobImag} alt="" className='w-full h-full hidden lg:flex' />
      <div>
      <img src={Mobile} alt="" className='w-full h-full lg:hidden' />
      <div className="absolute inset-0 bg-black opacity-50 lg:hidden"></div>
      </div>

      <div className='space-y-5 lg:space-y-10 absolute top-52 px-2 lg:px-28 text-center text-white w-full lg:hidden'>
              <h1 className='font-semibold text-xl'>Careers</h1>
              <h1 className='text-3xl lg:text-7xl font-bold'>Civil Engineering</h1>
              <p className='lg:text-2xl flex  justify-center gap-3'> <FaLocationArrow /> Delta state, Nigeria</p>
            </div>
    </section>

    <section className='px-5 lg:px-32 mt-24'> 
      <div className='text-xl space-y-4'>
        <p><span className='text-[#A02B2D] font-bold'>Role:</span> Digital Marketing Manager</p>
        <p><span className='text-[#A02B2D] font-bold'>Job Location:</span> Full Time - Remote</p>
      </div>

      <div className='mt-10 space-y-8 text-xl'>
        <h1 className='text-[#A02B2D] font-bold text-3xl'>Job Description</h1>
        <p className='leading-[35px]'>Overview: We are looking for a motivated and result-driven Digital Marketing Manager to join our marketing team to drive mobile acquisition; you will be responsible for setting up, implementing, and managing the overall company’s digital marketing strategy. Your central goal is to develop, implement, track, and optimise our digital marketing campaigns across all digital channels.</p>

<p className='leading-[35px]'>You will work with other cross-functional team members and rely heavily on qualitative and quantitative data to make informed decisions. Your duties will also include maintaining open communication with other departments during the whole process.</p>
      </div>

      <div className='mt-10'>
        <button className='w-full lg:w-[327px] bg-[#A02B2D] rounded-md h-[65px] text-white text-xl font-bold flex gap-3 justify-center items-center'>
          Apply for this Job <FaArrowRight />
        </button>
      </div>

      <section className='flex flex-wrap justify-center lg:justify-between mt-10'>
        {Positions.slice(0, 3).map((posi) => (
          <div 
            className='w-full sm:w-[80%] lg:w-[30%] h-auto lg:h-[593px] py-14 px-5 mt-5 space-y-10 border border-[#A02B2D] transition-transform transform hover:scale-105'  
            key={posi.id}
          >
            <div className='flex justify-between'>
              <h1 className='text-2xl font-bold'>{posi.position}</h1>
              <p className='border px-2 py-3'>{posi.type}</p>
            </div>

            <div className='flex space-x-4'>
              <img src={Location} alt="" />
              <p className='text-lg'>{posi.location}</p>
            </div>

            <p className='leading-[36px]'>{posi.description}</p>
            
            <div>
              <button className='w-full bg-[#A02B2D] h-[65px] rounded-full font-bold text-white'>
                Apply Now
              </button>
            </div>
          </div>
        ))}
      </section>
    </section>
  </div>
  )
}

export default Job
