import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Civil1 from '../utils/images/pro1.png';
import Civil2 from '../utils/images/pro12.png';
import Oil1 from '../utils/images/oil1.png';
import Oil2 from '../utils/images/oil2.png';
import Oil3 from '../utils/images/oil3.png'
import Project1 from '../utils/images/project1.png';
import Project2 from '../utils/images/project2.png';
import Training1 from '../utils/images/pro1.png';
import Training2 from '../utils/images/pro1.png';
import Supplies1 from '../utils/images/pro1.png';
import Supplies2 from '../utils/images/pro12.png';
import Supplies3 from '../utils/images/pro1.png';

type GalleryProps = {};

function Gallery(props: GalleryProps) {
  const [activeLink, setActiveLink] = useState<number>(0);
  const choose: string[] = [
    "Civil Engineering", "Oil & Gas", "Project Management", "Training Services", "General Contracts", "Supplies"
  ];

  // Content for each section
  const content = [
    {
      title: "Building the Future, One Structure at a Time",
      description: "We specialize in the design and construction of infrastructure that defines the modern landscape. From towering skyscrapers to expansive bridges, our civil engineering expertise ensures projects that are safe, sustainable, and visually striking. We manage every phase, from concept to completion, delivering on time and within budget.",
      images: [Civil1, Civil2],
      backgroundColor: '#FFE1E2',
      layout: 'stacked' // Stacked layout
    },
    {
      title: "Fueling Progress with Precision and Expertise",
      description: "Our Oil & Gas services cover the entire spectrum of the industry, from exploration and drilling to refining and distribution. We combine cutting-edge technology with industry best practices to maximize efficiency and safety. Our commitment to environmental stewardship ensures that we deliver energy solutions responsibly and sustainably.",
      images: [Oil1, Oil2,Oil3],
      backgroundColor: '#E1F1FF',
      layout: 'threeImages' // Side by side layout
    },
    {
      title: "Delivering Projects with Precision and Care",
      description: "We offer end-to-end project management services that ensure your projects are executed flawlessly. From initial planning to final delivery, we manage every aspect to ensure timely, cost-effective results. Our approach is collaborative, aligning with your business objectives to deliver outcomes that exceed expectations.",
      images: [Oil1, Oil2, Oil3],
      backgroundColor: '#655CFE',
      layout: 'threeImages' // Side by side layout
    },
    {
      title: "Empowering Your Workforce for Success",
      description: "Our tailored training programs are designed to equip your team with the skills and knowledge needed to excel in today’s competitive environment. Whether it’s technical training, safety protocols, or leadership development, we offer customized solutions that drive performance and ensure compliance across industries.",
      images: [Civil1, Civil2],
      backgroundColor: '#FFCE31',
      layout: 'stacked' // Stacked layout
    },
    {
      title: "Your Trusted Partner in Construction and Beyond",
      description: "We provide a wide range of general contracting services, from procurement to supply chain management. Our commitment to quality and timely delivery makes us a trusted partner in the industry.",
      images: [Oil1, Oil2,Oil3],
      backgroundColor: '#FF83D5',
      layout: 'threeImages' // Side by side layout
    },
    {
      title: "Reliable Sourcing for Your Critical Needs",
      description: "Our Supplies services ensure that you have access to the materials and equipment you need when you need them. We provide reliable procurement and logistics solutions that keep your projects running smoothly. From raw materials to specialized equipment, we ensure timely delivery and adherence to your project specifications.",
      images: [Oil1, Oil2,Oil3],
      backgroundColor: '#E1F1FF',
      layout: 'threeImages' // Side by side layout
    },
  ];

  // Automatically change content every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveLink(prevActiveLink => (prevActiveLink + 1) % content.length);
    }, 9000);

    return () => clearInterval(interval);
  }, []);

  const renderImages = () => {
    const { images, layout } = content[activeLink];

    if (layout === 'stacked') {
      return (
        <div className='relative'>
          <img src={images[0]} alt="First" className='lg:w-[302px] w-[200px] mx-auto -translate-y-20' />
          <img src={images[1]} alt="Second" className='lg:w-[302px] w-[200px] mx-auto absolute top-0 left-0 translate-x-14 translate-y-14' style={{ zIndex: -1 }} />
        </div>
      );
    } else if (layout === 'sideBySide') {
      return (
        <div className='flex flex-col lg:flex-row items-center'>
          <img src={images[0]} alt="First" className='lg:w-[302px] w-[200px] mx-auto' />
          <img src={images[1]} alt="Second" className='lg:w-[302px] w-[200px] mx-auto mt-5 lg:mt-0 lg:ml-5' />
        </div>
      );
    } else if (layout === 'threeImages') {
      return (
        <div className='flex flex-wrap lg:flex-nowrap   lg:flex-row items-center gap-5'>
          <img src={images[0]} alt="First" className='xl:w-[200px] lg:w-[100px] mx-auto lg:-translate-y-10' />
          <img src={images[1]} alt="Second" className='xl:w-[200px] lg:w-[100px] mx-auto' />
          <img src={images[2]} alt="Third" className='xl:w-[200px] lg:w-[100px] mx-auto lg:-translate-y-10' />
        </div>
      );
    }
  };

  return (
    <div className='lg:px-14 xl:px-28 mt-24'>
      <div className="space-y-5 text-center flex flex-col items-center">
        <h1 className="lg:w-[720px] text-[27px] lg:text-[55px] font-bold text-[#A02B2D]">Our Core Services</h1>
        <p className="lg:w-[724px] text-xl lg:text-2xl leading-9 lg:leading-[45px] font-medium">
          Comprehensive Solutions Tailored to Your Needs
        </p>
      </div>

      <section>
        {/* Navigation Section */}
        <section className='lg:px-5 xl:px-10 text-base z-50 relative'>
          <div className='text-center flex justify-around  lg:justify-center flex-wrap lg:flex-nowrap gap-5 lg:gap-2 bg-black rounded-3xl py-3 text-white mt-10'>
            {choose.map((ch, index) => (
              <p key={index} className='self-start'>
                <button
                  onClick={() => setActiveLink(index)}
                  className={activeLink === index ? 'border px-2 plg:x-5 py-2 rounded-full text-black bg-white' : 'text-white px-2 lg:px-5 py-2'}
                >
                  {ch}
                </button>
              </p>
            ))}
          </div>
        </section>

        {/* Content Section */}
        <AnimatePresence mode='wait'>
          <motion.section
            key={activeLink}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className='flex gap-14 flex-wrap lg:flex-nowrap justify-center items-center px-5 lg:px-20 border -mt-5 rounded-3xl relative z-10 h-auto pb-20 md:16'
            style={{ backgroundColor: content[activeLink].backgroundColor }}
          >
            <div className='mt-10 lg:mt-20'>
              <h1 className='font-bold text-2xl lg:text-[30px] lg:leading-[55px]'>{content[activeLink].title}</h1>
              <p className='text-lg mt-3 lg:mt-0 leading-10'>
                {content[activeLink].description}
              </p>
              <button className='px-5 py-2 bg-black text-white rounded-full mt-10'>Learn more</button>
            </div>

            {/* <div className='mt-10'>
              {renderImages()}
            </div> */}
          </motion.section>
        </AnimatePresence>
      </section>
    </div>
  );
}

export default Gallery;

