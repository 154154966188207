import React, { useEffect } from "react";
import Hero from "../utils/images/aboutus.png";
import Baby from "../utils/images/m-post-card-overlay.png";
import { Blogs } from "../utils/Data";
import { Outlet, Link, useLocation } from "react-router-dom";

function News() {
  const Location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Outlet />

      {Location.pathname === "/news" && (
        <section>
          <section className="relative">
            <div>
              <img
                src={Hero}
                alt="hero"
                className="w-full object-cover h-[50vh] lg:h-full"
              />
            </div>

            <div className="space-y-5 lg:space-y-10 absolute top-32 lg:top-14 xl:top-32 px-2 lg:px-28 text-center text-white">
              <h1 className="text-3xl lg:text-5xl xl:text-7xl font-bold">
                Stay Informed with the Latest Updates and Industry Insights
              </h1>
              <p className="lg:text-2xl">
                Explore our latest news, expert insights, and thought leadership
                articles to stay ahead in the industry.
              </p>
            </div>
          </section>

          <section className="mt-14 lg:mt-24 px-3 lg:px-14 xl:px-28 space-y-10">
            <div>
              <p className="text-lg leading-9 xl:text-2xl xl:leading-[55px]">
                Welcome to our News and Insights page, where we share the latest
                updates from VeeKITES Global services and valuable insights into
                the industries we serve. Our goal is to keep you informed about
                key trends, innovations, and developments that impact our work
                and your business. Whether it’s a new project, a company
                milestone, or an in-depth analysis of industry shifts, you’ll
                find it all here.
              </p>
            </div>

            <div>
              <img
                src={Baby}
                alt=""
                className="w-full h-screen object-cover lg:h-[547px]"
              />
            </div>
          </section>

          <section className="mt-24 px-5 lg:px-14 xl:px-28">
            <div>
              <h1 className="text-4xl font-bold">Latest Blog </h1>
            </div>

            <section className="flex flex-wrap justify-between">
              {Blogs.map((news) => (
                <div className="border rounded-lg space-y-5 mt-14 lg:w-[45%]  xl:w-[32%] py-5 px-5">
                  <p className="flex justify-center">
                    <img src={news.img} alt="" className="flex-grow" />
                  </p>
                  <div className="flex justify-start">
                    <p className="border text-start  px-2 text-[#4B6BFB] py-1 text-sm">
                      {news.category}
                    </p>
                  </div>
                  <div>
                    <Link to="tech">
                      <h1 className="font-semibold text-2xl">{news.title}</h1>
                    </Link>
                  </div>
                  <div className="flex space-x-4">
                    <p>
                      <img
                        src={news.proImg}
                        alt=""
                        className="h-20 w-20 object-contain rounded-[50%] object-center"
                      />
                    </p>
                    <p className="mt-1 text-[#97989F]">{news.name}</p>
                    <p className="mt-1 text-[#97989F]">{news.detail}</p>
                  </div>
                </div>
              ))}
            </section>
          </section>
        </section>
      )}
    </div>
  );
}

export default News;
