import Project1 from "./images/project1.jpeg";
import Project2 from "./images/project2.jpeg";
import Project3 from "./images/project3.jpeg";
import Project4 from "./images/project4.jpeg";
import Project5 from "./images/project5.jpeg";
import Profile from "./images/profile.png";
import pt1 from "./images/new/pt1.jpeg";
import pt2 from "./images/new/pt2.jpeg";
import pt3 from "./images/new/pt3.jpeg";
import pt4 from "./images/new/pt4.jpeg";
import pt5 from "./images/new/pt5.jpeg";
import pt6 from "./images/new/pt6.png";
import New1 from "./images/new1.png";
import Pro1 from "./images/team1.jpg";
import Pro2 from "./images/team2.jpg";
import Pro3 from "./images/team3.jpg";
import Team1 from "./images/team1.jpg";
import Team2 from "./images/team2.jpg";
import Team3 from "./images/team3.jpg";
import Team4 from "./images/team4.jpg";
import News2 from "./images/2News.png";
import News3 from "./images/pro1.png";
import Blog2 from "./images/2blog.png";
import Blog4 from "./images/blog4.png";
import Blog5 from "./images/blog5.png";
import Blog6 from "./images/blog6.png";
import Blog7 from "./images/blog7.png";
import Blog8 from "./images/blog8.png";
import Blog9 from "./images/blog9.png";

interface Team {
  id: number;
  img: string;
  name: string;
  position: string;
}

export const Teams: Team[] = [
  {
    id: 1,
    img: Team1,
    name: "Chinedu Okafor",
    position: "Project Engineer",
  },
  {
    id: 2,
    img: Team2,
    name: "Aisha Bello",
    position: "Civil Engineering Manager",
  },
  {
    id: 3,
    img: Team3,
    name: "Tunde Adeyemi",
    position: "Operations Director",
  },
  {
    id: 4,
    img: Team4,
    name: "Ngozi Nwosu",
    position: "Environmental Consultant",
  },
];

interface Navbar {
  id: number;
  name: string;
  link: string;
}

export const MainNavbarItems: Navbar[] = [
  { id: 1, name: "Home", link: "/" },
  { id: 2, name: "About", link: "/about" },
  { id: 4, name: "Projects", link: "/project" },
  { id: 3, name: "News & Insight", link: "/news" },
  { id: 6, name: "Contact ", link: "/contact" },
  { id: 7, name: "Careers ", link: "/career" },
  { id: 8, name: "Services", link: "/service" },
];

interface Project {
  id: number;
  title: string;
  summ: string;
  img: string;
}

export const Projects: Project[] = [
  {
    id: 1,
    title:
      "NATIONAL INSTITUTE OF CONSTRUCTION TECHNOLOGY AND MANAGEMENT UROMI, EDO STATE – 2023",
    summ: "Provision of 3 in 1 Solar Street light in Yengoa/Kolokuma-Opokuma, Bayelsa State.",
    img: Project1,
  },
  {
    id: 2,
    title: "FEDERAL COLLEGE OF AGRICULTURE, ISHIAGU - 2023",
    summ: "Supply of all – in – one Solar Street Lights to selected Communities in Akoko",
    img: Project2,
  },
  {
    id: 3,
    title: "FEDERAL POLYTECHNIC UKANA, AKWA IBOM, STATE – 2023",
    summ: "Supply of University textbooks to Azaiki public library in Yenago/Kolokuma-Opokuma Federal Constituency, Bayelsa State.",
    img: Project3,
  },
  {
    id: 4,
    title: "NIGERIAN BUILDING AND ROAD RESEARCH INSTITUTE - 2023",
    summ: "Renovation of Elemebiri Town Hall, Sagbama LGA, Sagbama/Ekeremor Federal Constituency, Bayelsa State.",
    img: Project4,
  },
  {
    id: 5,
    title: "PHARMACY COUNCIL OF NIGERIA - 2024",
    summ: "6 Days Free Medical Outreach to Bomadi/Patani Federal Constituency, Delta State",
    img: Project5,
  },
];

interface Other {
  id: number;
  title: string;
  summ: string;
  img: string;
  pro: string;
}

export const Others: Other[] = [
  {
    id: 1,
    title: "Federal Polytechnic Ukana",
    summ: "“Working with Veekites was a game-changer for our project. Their expertise in civil engineering and project management ensured we stayed on schedule and within budget. We couldn't have done it without them.”",
    img: pt5,
    pro: "Review from Thompson, Project Manager",
  },
  {
    id: 2,
    title: "Kalm Marine",
    summ: "“Veekites transformed our infrastructure project with their innovative solutions. Their attention to detail and commitment to sustainability set them apart. Our entire team was impressed by their professionalism and results.”",
    img: pt6,
    pro: "Review from Okechukwu, Lead Engineer",
  },
  {
    id: 3,
    title: "NBRRI",
    summ: "“Veekites delivered beyond our expectations. Their ability to manage complex urban development projects while maintaining efficiency and quality was outstanding. We look forward to partnering with them again in the future.”",
    img: pt1,
    pro: "Review from Michael, Senior Construction Supervisor",
  },
];

interface Brand {
  id: number;
  img: string;
}

export const Brands: Brand[] = [
  {
    id: 1,
    img: pt1,
  },
  {
    id: 2,
    img: pt2,
  },
  {
    id: 3,
    img: pt3,
  },
  {
    id: 4,
    img: pt4,
  },
  {
    id: 5,
    img: pt5,
  },
  {
    id: 6,
    img: pt6,
  },
];

interface FAQ {
  id: number;
  title: string;
  summ: string;
}
export const FAQs: FAQ[] = [
  {
    id: 1,
    title: "How does it work",
    summ: "Lorem ipsum yuas yua uyfa uya yuta iutga iuta",
  },
  {
    id: 2,
    title: "Is my data course?",
    summ: "Lorem ipsum yuas yua uyfa uya yuta iutga iuta",
  },
  {
    id: 3,
    title: "Does Veekites work well large team?",
    summ: "Lorem ipsum yuas yua uyfa uya yuta iutga iuta",
  },
  {
    id: 4,
    title: "How do i get to reach us",
    summ: "Lorem ipsum yuas yua uyfa uya yuta iutga iuta",
  },
];

interface New {
  id: number;
  img: string;
  proImg: string;
  title: string;
  detail: string;
  name: string;
  category: string;
}

export const Blogs: New[] = [
  {
    id: 1,
    img: New1,
    proImg: Pro1,
    title:
      "Innovative Solutions in Civil Engineering: Shaping Urban Landscapes",
    detail: "August 20, 2024",
    name: "Tracey Sochi",
    category: "Civil Engineering",
  },
  {
    id: 2,
    img: News2,
    proImg: Pro2,
    title: "Advancements in Oil and Gas: Ensuring Safety and Sustainability",
    detail: "September 15, 2024",
    name: "Jordan Ayodele",
    category: "Oil and Gas",
  },
  {
    id: 3,
    img: News3,
    proImg: Pro3,
    title: "Global Project Management Trends: Navigating Complex Challenges",
    detail: "October 5, 2024",
    name: "Alex Sochi",
    category: "Project Management",
  },
];

export const News: New[] = [
  {
    id: 1,
    img: New1,
    proImg: Pro1,
    title:
      "Innovative Solutions in Civil Engineering: Shaping Urban Landscapes",
    detail: "August 20, 2024",
    name: "Tracey Sochi",
    category: "Civil Engineering",
  },
  {
    id: 2,
    img: News2,
    proImg: Pro2,
    title: "Advancements in Oil and Gas: Ensuring Safety and Sustainability",
    detail: "September 15, 2024",
    name: "Jordan Ayodele",
    category: "Oil and Gas",
  },
  {
    id: 3,
    img: News3,
    proImg: Pro3,
    title: "Global Project Management Trends: Navigating Complex Challenges",
    detail: "October 5, 2024",
    name: "Alex Sochi",
    category: "Project Management",
  },
];

interface work {
  id: number;
  title: string;
  summ: string;
}

export const WorkWithUs: work[] = [
  {
    id: 0,
    title: "Innovative Environment",
    summ: "We encourage creativity and innovation, empowering our team to explore new ideas and solutions.",
  },
  {
    id: 1,
    title: "Growth Opportunities",
    summ: "From continuous learning to career advancement, we invest in our people’s growth.",
  },
  {
    id: 2,
    title: "Diverse Team ",
    summ: "Our inclusive culture values diverse perspectives, fostering a collaborative and supportive workplace.",
  },
  {
    id: 3,
    title: "Impactful Work",
    summ: "Join a company where your work makes a difference. We tackle challenging projects that drive positive change.",
  },
];

interface position {
  id: number;
  position: string;
  type: string;
  location: string;
  description: string;
}

export const Positions: position[] = [
  {
    id: 0,
    position: "Civil Engineering",
    type: "Full Time",
    location: "Delta State, Nigeria",
    description:
      "We are seeking a skilled and motivated Civil Engineer to join our dynamic team. In this role, you will be responsible for designing, planning, and overseeing construction projects that range from roadways and bridges to large-scale infrastructure developments.",
  },
  {
    id: 1,
    position: "Project Manager",
    type: "Full Time",
    location: "Lagos, Nigeria",
    description:
      "We are looking for an experienced Project Manager to oversee our engineering projects. You will be responsible for coordinating teams, managing budgets, and ensuring projects are completed on time and within scope.",
  },
  {
    id: 2,
    position: "Environmental Consultant",
    type: "Part Time",
    location: "Abuja, Nigeria",
    description:
      "Join our team as an Environmental Consultant to assess and manage the environmental impact of our projects. You will conduct research, prepare reports, and advise on sustainability practices.",
  },
  {
    id: 3,
    position: "Oil and Gas Engineer",
    type: "Contract",
    location: "Port Harcourt, Nigeria",
    description:
      "We are seeking an Oil and Gas Engineer to support our operations in the oil and gas sector. You will work on project development, production optimization, and compliance with safety regulations.",
  },
  {
    id: 4,
    position: "Structural Engineer",
    type: "Full Time",
    location: "Enugu, Nigeria",
    description:
      "We are looking for a Structural Engineer to design and analyze structures for various projects. You will collaborate with architects and construction teams to ensure structural integrity and compliance with regulations.",
  },
];
